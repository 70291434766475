import React from "react";
import Header from "../Header/Header";
import "./Hero.css";
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import Heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"
import {motion} from "framer-motion"
import NumberCounter from "number-counter"

const Hero = () => {
  const transition = {type: 'spring', duration: 3}
  const mobile = window.innerWidth<=768 ? true : false;

  return (
    <div className="hero" id="home">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        {/* the best ad */}
        <div className="the-best-ad">
          <motion.div
          initial = {{left: mobile ? '163px':'238px'}}
          whileInView={{left: '8px'}}
          transition ={{...transition, type: 'tween'}}
          ></motion.div>
          <span>The Best Fitness Club in the Town</span>
        </div>
        {/* the hero text */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>
          <div>
            <span>
              In here we will help you to shape and build your ideal Body and
              live up your life to fullest
            </span>
          </div>
        </div>
        {/* fingures */}
        <div className="figures">
          <div>
            <span><NumberCounter end={140} start={102} delay={4} preFix="+"></NumberCounter></span>
            <span>expert coaches</span>
          </div>
          <div>
            <span><NumberCounter end={978} start={800} delay={4} preFix="+"></NumberCounter></span>
            <span>members joined</span>
          </div>
          <div>
            <span><NumberCounter end={50} start={30} delay={4} preFix="+"></NumberCounter></span>
            <span>fitness programs</span>
          </div>
        </div>
        {/* hero buttons */}
        <div className="hero-buttons">
          <span className="btn">Get Started</span>
          <span className="btn">Learn More</span>
        </div>
      </div>

      {/* Right side */}
      <div className="right-h">
        <button className="btn">Join Now</button>
        <motion.div
        initial={{right: "-1rem"}}
        whileInView={{right: '7rem'}}
        transition={transition}
        className="heart-rate">
          <img src={Heart} alt="" />
          <span>Heart Rate </span>
          <span>116 bpm</span>
        </motion.div> 
      </div>

      {/* hero images */}
      <img src={hero_image} alt="" className="hero-image"/>
      <motion.img 
      initial={{right: "20rem"}}
      whileInView={{right: '35rem'}}
      transition={transition}
      src={hero_image_back} alt="" className="hero-image-back"/>

      {/* Calories */}
      <motion.div
        initial={{right: "67rem"}}
        whileInView={{right: '48rem'}}
        transition={transition}
         className="calories">
        <img src={Calories} alt="" />
        <div>
          <span>Calories Burned </span>
          <span>220 Kcal</span>
        </div>
      </motion.div>
    </div>
  );
};

export default Hero;
